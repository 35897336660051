<template>
  <div class="toast">{{message}}</div>
</template>

<script>
import { reactive } from 'vue'
export default {
  props: ['message']
}
export const useToastEffect = () => {
  const toastData = reactive({
    showToast: false,
    toastMessage: ''
  })
  const showToast = (message) => {
    toastData.showToast = true
    toastData.toastMessage = message
    setTimeout(() => {
      toastData.showToast = false
      toastData.toastMessage = ''
    }, 2000)
  }
  return { toastData, showToast }
}
</script>

<style scoped>
.toast {
  position: fixed;
  left: 50%;
  top: 20%;
  transform: translate(-50%, -50%);
  padding: 8px 12px;
  background: rgba(180, 180, 180, 0.7);
  border-radius: 8px;
  color: #FFF;
}
</style>