<template>
  <div class="main-content">
    <div class="btn-container">
      <button class="room-btn create" @click="createRoom()">创建房间</button>
      <button class="room-btn join" @click="joinRoom()">加入房间</button>
    </div>
    <div class="mask" v-if="show">
      <div class="content">
        <div class="input-container">
          <input v-if="!isCreate" v-model="roomId" placeholder="请输入房间号"/>
          <input v-if="isCreate" v-model="videoUrl" placeholder="请输入视频地址, 支持mp4/hls"/>
        </div>
        <div class="btn-container">
          <button class="cancel-btn"  @click="onCancel">取消</button>
          <button :class="isCreate? 'confirm-btn create' : 'confirm-btn join'" @click="onConfirm">{{isCreate ? '创建': '加入'}}</button>
        </div>
      </div>
    </div>
    <Toast v-if="toastData.showToast" :message="toastData.toastMessage" />
  </div>
</template>

<script>
import Toast, { useToastEffect } from "@/plugin/toast/Toast.vue" // 1、在需要的页面引用
import axios from "axios";
axios.defaults.timeout = 6000
const basic_url = 'https://movie.lvaohui.top/api'

export default {
  name: 'Hello',
  components: {Toast},
  data() {
    return {
      isCreate: true,
      show: false,
      roomId: '',
      videoUrl: ''
    }
  },
  setup() {
    const { toastData, showToast } = useToastEffect() // 3、
    return {toastData, showToast}
  },
  mounted() {
    let noRoom = this.$route.query.noRoom;
    if(noRoom) {
      this.showToast('房间不存在，请重新进入!')
    }
  },
  methods: {
    createRoom() {
      setTimeout(()=>{
        this.isCreate = true
        this.show = true
      }, 300)
    },
    joinRoom() {
      setTimeout(()=>{
        this.isCreate = false
        this.show = true
      }, 300)
    },
    onCancel() {
      this.show = false
    },
    onConfirm() {
      if(this.isCreate) { // 创建房间
        if(this.videoUrl === '') {
          this.showToast('地址不能为空')
          return
        }
        console.log('url: ' + this.videoUrl)
        axios.get(basic_url + '/createRoom?video_url='+this.videoUrl).then((response) => {
          console.log(response.status, response.data)
          let resp = response.data
          if(resp.code !== 200) {
            this.showToast(resp.message)
            return
          }
          let room_id = resp.data.room_id
          this.$router.push({path:'/room', query: {id: room_id , url: this.videoUrl}})
        }).catch((error)=>{
          this.showToast('创建房间出错!')
          console.log(error)
        })
      }else {
        if(this.roomId === '') {
          this.showToast('房间号不能为空')
          return
        }
        console.log('room id: ' + this.roomId)
        axios.get(basic_url + '/joinRoom?room_id='+this.roomId).then((response) => {
          console.log(response.status, response.data)
          let resp = response.data
          if(resp.code !== 200) {
            this.showToast('获取房间信息失败!')
            return
          }
          let video_url = resp.data.video_url
          if(video_url === '') {
            this.showToast('房间不存在!')
            return;
          }
          this.$router.push({path:'/room', query: {id: this.roomId , url: video_url}})
        }).catch((error)=>{
          this.showToast('获取房间信息出错!')
          console.log(error)
        })
      }
    }
  },
}
</script>

<style scoped>
.main-content {
  height: 100%;
  width: 100%;
}

.main-content .btn-container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(0,0,0,0.75);
}

.room-btn {
  width: 200px;
  height: 80px;
  margin: 20px 0;
  border: 0;
  border-radius: 20px;
  text-align: center;
  color: white;
  font-size: 20px;
  font-weight: bold;
  box-shadow: 7px 6px 28px 1px rgba(200,200,200, 0.4);
}
.room-btn:active {
  transform: scale(0.97);
  box-shadow: 3px 2px 22px 1px rgba(200,200,200, 0.4);
}

.create {
  background-color: #42b983;
}
.join {
  background-color: coral;
}

.mask {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.mask .content{
  width: 350px;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: dimgrey;
}

.mask .content .input-container {
  width: 100%;
  height: 180px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.mask .content .input-container input {
  width: 92%;
  height: 50px;
  border: 1px solid #000;
  border-radius: 10px;
  font-size: 16px;
  text-align: center;
}

.mask .content .btn-container {
  width: 100%;
  height: 50px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}
.mask .content .btn-container button {
  width: 100%;
  height: 50px;
  border: 0;
  text-align: center;
  color: white;
  font-size: 20px;
  font-weight: bold;
}
.cancel-btn {
  background-color: darkgrey;
  border-bottom-left-radius: 15px;
}
.confirm-btn {
  border-bottom-right-radius: 15px;
}


</style>