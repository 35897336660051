<template>
  <input v-model="msg" placeholder="消息"/>
  <button @click="sendClick()">发送</button>
</template>

<script>
import Peer from "peerjs";
import eventBus from "@/libs/eventBus";

var peer = null
var conns = new Map()
var callConns = new Map()
var localStream = null
function initConn(conn) {
  conn.on("open", ()=>{
    console.log('conn open')
    conns.set(conn.peer, conn)
  })
  conn.on("data", (data) => {
    console.log("Received data", data);
    // TODO:将消息展示到界面
  });
  conn.on('close', ()=>{
    console.log("on conn close, id:", conn.peer);
    closeConn(conn.peer)
  })
}

function connPeer(id) {
  console.log('connect to ' + id)
  let conn = peer.connect(id);
  initConn(conn)
  callPeer(id)
}

function closeConn(id) {
  if(conns.has(id)) {
    conns.delete(id)
  }
  if(callConns.has(id)) {
    callConns.get(id).conn.close()
  }
  console.log('conn closed, peerid :', id)
}

function initCallConn(callConn) {
  callConn.on('stream', function(remoteStream) {
    console.log("call remote stream", remoteStream);
    callConns.set(callConn.peer, {
      conn: callConn,
      stream: remoteStream
    })
    let ae = document.createElement('audio')
    ae.id = callConn.peer
    ae.autoplay = 'true'
    ae.srcObject = remoteStream
    ae.play()
    document.body.appendChild(ae)
  });
  callConn.on('close', function() {
    console.log("on call close, id:", callConn.peer);
    closeCall(callConn.peer)
  });
}

function callPeer(id) {
  getLocalStream(
    ()=>{
      let callConn = peer.call(id, localStream)
      initCallConn(callConn)
    }
  )
}
function closeCall(id) {
  let ae = document.getElementById(id)
  if(ae) {
    document.body.removeChild(ae)
  }
  if(callConns.has(id)) {
    callConns.delete(id)
  }
  console.log('close call, id:', id)
}

function getLocalStream(callback) {
  if(localStream) {
    callback()
    return
  }
  console.log('get local stream')
  getCompatibleUserMedia(
      {
        video:false,
        audio: true
      },
      (stream)=> {
        localStream = stream
        console.log('获取麦克风权限成功')
        callback()
      },
      (err)=> {
        localStream = null
        console.log('获取麦克风权限失败', err)
      });
}

//兼容的getUserMedia
function getCompatibleUserMedia(constraints, successCallback, errorCallback) {
  // navigator.mediaDevices.getUserMedia(constraints).then((stream)=>{
  //   console.log('get stream', stream)
  //   successCallback(stream)
  // }).catch((err)=>{
  //   errorCallback(err)
  // })
  var media = (navigator.getUserMedia || navigator.webkitGetUserMedia ||navigator.mozGetUserMedia ||navigator.msGetUserMedia);
  if (media) {
    media.call(navigator, constraints, successCallback, errorCallback);
    return true;
  } else {
    console.log("Your browser does not support the getUserMedia API.");
    return false;
  }
}

export default {
  name: "ChatRoom",
  props: {
    roomId: String
  },
  data() {
    return {
      // roomId: '1234',
      peerId: '',
      friendId: '',
      msg: ''
    }
  },
  created() {
    console.log('chat room create')
    eventBus.on('connect2Peers', (peers)=>{
      for(var i=0;i<peers.length;++i) {
        connPeer(peers.at(i))
      }
    })
    eventBus.on('disconnect', (peerId)=>{
      if(conns.has(peerId)) {
        conns.get(peerId).close()
      }else if(callConns.has(peerId)) {
        callConns.get(peerId).conn.close()
      }
    })
    let that = this
    peer = new Peer({
      host: "movie.lvaohui.top",
      port: 443,
      path: "/test",
      pingInterval: 10 * 1000
    })
    peer.on('open', function(id) {
      that.peerId = id
      document.getElementById('myid').textContent = id
      console.log('My peer ID is: ' + id);
      eventBus.emit('chatReady', id)
    });
    peer.on("connection", (conn) => {
      console.log('in conn')
      initConn(conn)
    });
    peer.on('call', (callCoon)=>{
      console.log('recv call from', callCoon.peer)
      getLocalStream(()=>{
        callCoon.answer(localStream)
        initCallConn(callCoon)
      })
    })
  },
  mounted() {
  },
  beforeUnmount() {
    // if(this.peerId !== '') {
    //   leaveAndDisconnect(this.peerId, this.roomId)
    // }
    eventBus.off('connect2Peers')
    eventBus.off('disconnect')
    if(peer) {
      peer.destroy()
      peer = null
    }
    conns.clear()
    callConns.clear()
    this.peerId = ''
  },
  methods: {
    sendClick() {
      for(var [id, conn] of conns){
        conn.send(this.msg)
        console.log('send data:', this.msg, 'to:', id)
      }
    }
  }
}
</script>

<style scoped>

</style>