import { createApp } from 'vue'
import App from './App.vue'
import router from "@/router";
import './assets/css/all.css'


import VueVideoPlayer  from '@videojs-player/vue'

import 'video.js/dist/video-js.css'
// import 'vue-video-player/src/custom-theme.css'
const hls =require("videojs-contrib-hls")


const app = createApp(App)
app.use(router)
app.use(VueVideoPlayer)
app.use(hls)
app.mount('#app')
