import { createRouter,createWebHistory } from "vue-router";
import Hello from "@/components/views/Hello.vue";
// import VideoRoom from "@/components/views/VideoRoom.vue";
import Room from "@/components/views/Room.vue";
// import ChatRoom from "@/components/views/ChatRoom.vue";
const router = createRouter({
    history:createWebHistory(),
    routes:[
        {
            path:'/',
            component:Hello,
        },
        {
            path:'/room',
            component:Room,
        }

    ]
})

export default router;
