<template>
<VideoRoom id="my-player" :url="url" :roomId="roomId" :width="width" :height="width/1.78+60"></VideoRoom>
<ChatRoom :roomId="roomId"></ChatRoom>
</template>

<script>
import VideoRoom from "@/components/views/VideoRoom.vue";
import ChatRoom from "@/components/views/ChatRoom.vue";
export default {
  components: {ChatRoom, VideoRoom },
  name: "Room",
  data() {
    return {
      url: '',
      roomId: '',
      width: document.body.clientWidth,
      height: document.body.clientHeight
    }
  },
  created() {
    this.roomId = this.$route.query.id;
    this.url = this.$route.query.url;
  }
}
</script>

<style scoped>
/*#video-room {*/
/*  width: 100%;*/
/*  height: 35%;*/
/*}*/
</style>