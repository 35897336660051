<template>
<!--  <div class="main">-->
    <video-player
        id="my-player"
        class="video-player vjs-big-play-centered"
        :src='url'
        crossorigin="anonymous"
        playsinline
        controls
        ref="vuePlayer"
        :volume="0.6"
        :playback-rates="[0.5, 0.75, 1.0, 1.25, 1.5, 1.75, 2.0]"
        :muted="true"
        @play="onPlay()"
        @pause="onPause()"
        @ratechange="onRateChange($event)"
        @playbackrateschange="onPlaybackRatesChange($event)"
        @timeupdate="onTimeUpdate($event)"
    />
<!--  </div>-->
</template>

<script>
import eventBus from "@/libs/eventBus";

const OP_PLAY = 10001
const OP_PAUSE = 10002
const OP_RATE = 10003

const {io} = require('socket.io-client')
var socket = null

export default ({
  name: 'VideoRoom',
  props: {
    url: String,
    roomId: String
  },
  data() {
    return {
      recvOpMap: {},
      // width: document.body.clientWidth,
      // height: document.body.clientHeight - 100,
      current: 0,
    }
  },
  created() { //页面渲染前
    console.log('video room create')
    let that = this
    eventBus.on('chatReady', (peerId)=>{
      socket = io('https://movie.lvaohui.top/sync')
      socket.on('connect', ()=>{
        console.log('socket connect!')
        socket.emit('join', that.roomId, peerId)
      })
      socket.on('disconnect', ()=>{
        console.log('socker disconnect!')
      })
      socket.on('no_room', ()=>{
        that.$router.push({path:'/', query: {noRoom: true}})
      })
      socket.on('peers', (peers)=>{
        console.log('peers: ', peers)
        eventBus.emit('connect2Peers', peers)
      })
      socket.on('peer_disconnect', (peerId)=>{
        console.log('peer disconnect', peerId)
        eventBus.emit('disconnect', peerId)
      })
      socket.on('op', (op, arg) => {
        let key = ''
        switch (op) {
          case OP_PLAY:
            console.log('recv play on ' + arg)
            if(!that.player.paused()) { // 播放状态，只用设置时间
              that.player.currentTime(arg)
              that.current = arg
              return
            }
            key = '' + OP_PLAY + arg
            if(key in that.recvOpMap) {
              that.recvOpMap[key]++
            }else{
              that.recvOpMap[key] = 1
            }
            console.log('recv play', this.recvOpMap)
            that.player.currentTime(arg)
            that.current = arg
            that.player.play()
            break;
          case OP_PAUSE:
            console.log('recv pause on ' + arg)
            if(that.player.paused()) { // 暂停状态，只用设置时间
              that.player.currentTime(arg)
              that.current = arg
              return
            }
            key = '' + OP_PAUSE + arg
            if(key in that.recvOpMap) {
              that.recvOpMap[key]++
            }else{
              that.recvOpMap[key] = 1
            }
            console.log('recv pause', this.recvOpMap)
            that.player.currentTime(arg)
            that.current = arg
            that.player.pause()
            break;
          case OP_RATE:
            console.log('recv current rate: ' + arg)
            key = '' + OP_RATE + arg
            if(key in that.recvOpMap) {
              that.recvOpMap[key]++
            }else{
              that.recvOpMap[key] = 1
            }
            that.player.playbackRate(arg)
            break;
          default:
            break;
        }
      })
    })
  },
  mounted() { //页面渲染后
  },
  beforeUnmount() {
    if(socket) {
      socket.disconnect()
      socket = null
    }
    eventBus.off('chatReady')
  },
  methods: {
    onPlaying(){
      console.log('on playing')
    },
    onWaiting() {
      console.log('on waiting')
    },
    onPlay() {
      let key = '' + OP_PLAY + this.current
      console.log('play key='+key, this.recvOpMap)
      if(key in this.recvOpMap) {
        this.recvOpMap[key]--
        if(this.recvOpMap[key] <= 0) {
          delete this.recvOpMap[key]
        }
      }else {
        console.log('play on ' + this.current)
        // 执行发送程序
        socket.emit('op', OP_PLAY, this.current)
      }
    },
    onPause() {
      let key = '' + OP_PAUSE + this.current
      console.log('pause key='+key, this.recvOpMap)
      if(key in this.recvOpMap) {
        this.recvOpMap[key]--
        if(this.recvOpMap[key] <= 0) {
          delete this.recvOpMap[key]
        }
      }else {
        console.log('pause on ' + this.current)
        // 执行发送程序
        socket.emit('op', OP_PAUSE, this.current)
      }
    },
    onRateChange(event) {
      let player = event.target.player.cache_
      if(!player) {
        return
      }
      let key = '' + OP_RATE + player.lastPlaybackRate
      if(key in this.recvOpMap) {
        this.recvOpMap[key]--
        if(this.recvOpMap[key] <= 0) {
          delete this.recvOpMap[key]
        }
      }else {
        console.log('rate change: ', player.lastPlaybackRate)
        // 执行发送程序
        socket.emit('op', OP_RATE, player.lastPlaybackRate)
      }
    },
    onTimeUpdate(event) {
      let player = event.target.player.cache_
      if(!player) {
        return
      }
      if(this.player.paused() && this.current !== player.currentTime) {
        socket.emit('op', OP_PAUSE, player.currentTime)
      }
      if(!this.player.paused() && Math.abs((this.current - player.currentTime)) >= 1){ // 拖动进度条
        socket.emit('op', OP_PLAY, player.currentTime)
      }
      this.current = player.currentTime
    }
  },
  computed: {
    player() {
      return document.getElementById("my-player").player
    }
  }
})

</script>

<style scoped>
/*.main {*/
/*  width: 100%;*/
/*  height: 100%;*/
/*  !*padding-top: 50px;*!*/
/*  background-color: rgba(0,0,0,0.8);*/
/*  display: flex;*/
/*  flex-direction: column;*/
/*  justify-content: center;*/
/*  align-items: center;*/
/*}*/
/*.room {*/
/*  position: fixed;*/
/*  top: 10px;*/
/*  font-size: 20px;*/
/*  color: #FFFFFF;*/
/*}*/

</style>